import React from 'react';
import { useLocation } from 'react-router-dom';

const ResultPage = () => {
    // Hook to access the location object which contains the URL
    const location = useLocation();

    // Function to get the value of the 'koffi_result' query parameter
    const getQueryParam = (param) => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get(param);
    };

    // Extract and decode the 'koffi_result' parameter
    const koffiResultEncoded = getQueryParam('koffi_result');
    let koffiResultDecoded = '';

    if (koffiResultEncoded) {
        try {
            koffiResultDecoded = atob(koffiResultEncoded); // Decode Base64
        } catch (error) {
            console.error('Failed to decode Base64:', error);
        }
    }

    // Parse the decoded string to JSON
    let koffiResult = {};
    try {
        koffiResult = JSON.parse(koffiResultDecoded);
    } catch (error) {
        console.error('Failed to parse JSON:', error);
    }
    const result = koffiResult.result

    return (
        <div className="mt-5 d-flex justify-content-center">
            <p className="fs-2">Signing Result: <b>{result}</b></p>
        </div>
    );
};

export default ResultPage;