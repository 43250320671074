import React from 'react';
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

function ConfigPage(props) {
    const [validated, setValidated] = useState(false);
    const [privateKey, setPrivateKey] = useState('');
    const [clientId, setClientId] = useState('');
    const [keypairId, setKeypairId] = useState('');
    const [emailImpersonate, setEmailImpersonate] = useState('');
    const [signingRequestTemplateSinger, setSigningRequestTemplateSinger] = useState('');
    const [signingRequestTemplate, setSigningRequestTemplate] = useState('');


    useEffect(() => {
        fetchData();
    }, []);
    function fetchData() {
        fetch('/api/app-demo/config', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => {
            res.json().then(result => {
                setClientId(result.client_id);
                setPrivateKey(result.private_key);
                setKeypairId(result.keypair_id);
                setEmailImpersonate(result.email_impersonate);
                setSigningRequestTemplateSinger(result.signing_request_template_singer);
                setSigningRequestTemplate(result.signing_request_template);
            });
        })
    }


    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            try {
                const response = await fetch('/api/app-demo/config', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        private_key: privateKey,
                        client_id: clientId,
                        keypair_id: keypairId,
                        email_impersonate: emailImpersonate,
                        signing_request_template_singer: signingRequestTemplateSinger,
                        signing_request_template: signingRequestTemplate
                    })
                });

                if (response.ok) {
                    alert('Update config completed');
                } else {
                    // Handle errors
                    console.error('Update config failed:', response.statusText);
                }
            } catch (error) {
                console.error('Error occurred during update config failed:', error);
            }
        }

        setValidated(true);
    };


    return (<Container>
            <Row>
                <Col className="mb-3 mt-3 fw-bold">DEMO SAMPLE</Col>
            </Row>
            <Row>
                <Col>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group controlId="validationCustom01">
                            <Form.Label>privateKey</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={privateKey}
                                rows={10}
                                onChange={(e) => setPrivateKey(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="validationCustom01">
                            <Form.Label>clientId</Form.Label>
                            <Form.Control
                                type="text"
                                value={clientId}
                                onChange={(e) => setClientId(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="validationCustom01">
                            <Form.Label>keypairId</Form.Label>
                            <Form.Control
                                type="text"
                                value={keypairId}
                                onChange={(e) => setKeypairId(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="validationCustom01">
                            <Form.Label>emailImpersonate</Form.Label>
                            <Form.Control
                                type="text"
                                value={emailImpersonate}
                                onChange={(e) => setEmailImpersonate(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="validationCustom01">
                            <Form.Label>signingRequestTemplateSinger</Form.Label>
                            <Form.Control
                                type="text"
                                value={signingRequestTemplateSinger}
                                onChange={(e) => setSigningRequestTemplateSinger(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="validationCustom01">
                            <Form.Label>signingRequestTemplate</Form.Label>
                            <Form.Control
                                type="text"
                                value={signingRequestTemplate}
                                onChange={(e) => setSigningRequestTemplate(e.target.value)}
                            />
                        </Form.Group>
                        <Button className="mt-3" type="submit">Update</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default ConfigPage;