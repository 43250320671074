import React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

function CreateRequestPage(props) {
    const [validated, setValidated] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [url, setUrl] = useState('');
    const [id, setId] = useState('');

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            try {
                const response = await fetch('/api/app-demo', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ name, email })
                });

                if (response.ok) {
                    const result = await response.json();
                    setUrl(result.documents[0].thumbnail_url);
                    setId(result.id);
                    console.log('Form submitted successfully:', result.documents[0].thumbnail_url);
                } else {
                    // Handle errors
                    console.error('Form submission failed:', response.statusText);
                }
            } catch (error) {
                console.error('Error occurred during form submission:', error);
            }
        }

        setValidated(true);
    };

    const handleAdditionalApiCall = async () => {
        try {
            const response = await fetch('/api/app-demo/sign', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    email: email
                })
            });

            if (response.ok) {
                const result = await response.json();
                window.open(result.signing_url, '_blank');
            } else {
                // Handle errors
                console.error('Additional API call failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error occurred during additional API call:', error);
        }
    };


    return (<Container>
            <Row>
                <Col className="mb-3 mt-3 fw-bold">DEMO SAMPLE</Col>
            </Row>
            <Row>
                <Col>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group controlId="validationCustom01">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a name.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="validationCustom02">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide an email.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button className="mt-3" type="submit">Create</Button>
                    </Form>
                </Col>
                <Col>{url && (
                    <div className="mt-4">
                        <Button className="mt-3" onClick={handleAdditionalApiCall}>Sign</Button>
                        <img src={url} alt="Submitted data" style={{ width: '100%' }} />
                    </div>
                )}</Col>
            </Row>
        </Container>
    );
}

export default CreateRequestPage;